



















































































import { Vue, Component, Prop } from "vue-property-decorator";
import OrderRowContent from "@/components/OrderRowContent.vue";
import { Order } from "@/types/orders";
import { OrderStatus } from "@/types/orders";
import { OrderColor } from "@/types/orders";
import { OrderFile } from "@/types/orders";
import { ordersModule } from "@/store/orders";
import { AxiosError } from "axios";
import moment from "moment";
@Component({
  components: { OrderRowContent },
})
export default class OrderContent extends Vue {
  @Prop({ required: true }) order!: Order;
  @Prop({ required: true }) isAdmin!: boolean;
  showOrderRowContent = false;
  status = 0;
  orderFiles: OrderFile[] = [];
  statusColor = "";
  orderTextColor = "";
  fieldsAreFilled = false;

  get OrderStatus(): string {
    switch (this.order.status) {
      case 0:
        this.statusColor = OrderColor.NEW;
        this.orderTextColor = "text-new";
        return "NEW";
      case 1:
        this.statusColor = OrderColor.ACCEPTED;
        this.orderTextColor = "text-accepted";
        return "ACCEPTED";
      case 2:
        this.statusColor = OrderColor.PLANNED;
        this.orderTextColor = "text-planned";
        return "PLANNED";
      case 3:
        this.statusColor = OrderColor.DELIVERED;
        this.orderTextColor = "text-delivered";
        return "DELIVERED";
      case 4:
        this.statusColor = OrderColor.COMPLETED;
        this.orderTextColor = "text-completed";
        return "COMPLETED";
      default: {
        return "";
      }
    }
  }

  get orderCompany() {
    return localStorage.getItem("company");
  }

  get plannedShippedDateStatus() {
    if (this.order.plannedShipmentDate && this.order.desiredDelivery) {
      return (
        this.admissionDateFormat(this.order.plannedShipmentDate) >
        this.admissionDateFormat(this.order.desiredDelivery)
      );
    }
    return false;
  }

  get plannedStatus() {
    if (this.plannedShippedDateStatus) {
      return "bg-warning";
    } else if (this.order.status === 0 || this.order.status === 1) {
      return "bg-statusUnComplated";
    } else if (this.order.status > 1) {
      return "bg-statusCompleted";
    }
    return "";
  }

  mounted() {
    this.changedStatus();
    this.fieldsAreFilled = this.allFieldsAreFilled() ? true : false;
  }

  toggle() {
    this.showOrderRowContent = !this.showOrderRowContent;
  }

  admissionDateFormat(date: Date): string {
    return moment(date).format("YYYY-MM-DD");
  }

  changedStatus() {
    this.order.status = OrderStatus.NEW;
    if (this.checkAccepted()) {
      this.order.status = OrderStatus.ACCEPTED;
    }
    if (this.checkAccepted() && this.checkPlanned()) {
      this.order.status = OrderStatus.PLANNED;
    }
    if (this.checkAccepted() && this.checkPlanned() && this.checkDelivered()) {
      this.order.status = OrderStatus.DELIVERED;
    }
    if (
      this.checkAccepted() &&
      this.checkPlanned() &&
      this.checkDelivered() &&
      this.checkCompleted()
    ) {
      this.order.status = OrderStatus.COMPLETED;
    }
  }

  allFieldsAreFilled() {
    return (
      this.order.acceptedDate &&
      this.order.orderAccepted &&
      this.order.plannedShipmentDate &&
      this.order.deliveryType.length > 0 &&
      this.order.shippedDate &&
      this.order.trackingIds.length > 0 &&
      this.order.trackingIds[0] != ""
    );
  }

  checkAccepted() {
    return this.order.acceptedDate && this.order.orderAccepted;
  }

  checkPlanned() {
    return this.order.plannedShipmentDate && this.order.deliveryType.length > 0;
  }

  checkDelivered():boolean {
    return !!(
      this.order.shippedDate &&
      this.order.trackingIds.length > 0 &&
      this.order.trackingIds[0] != "" &&
      (this.order.freightCompany != null && this.order.freightCompany.length > 0)
    );
  }

  checkCompleted() {
    return this.order.orderCompleted;
  }

  updateOrder(callback: any) {
    const formData = new FormData();
    formData.append("id", this.order.id.toString());
    formData.append("offer", this.order.offer.toString());
    formData.append("orderNo", this.order.orderNo);
    if (this.order.desiredDelivery) {
      formData.append("desiredDelivery", this.order.desiredDelivery.toString());
    }
    formData.append("status", this.order.status.toString());

    formData.append("trackingIds", JSON.stringify(this.order.trackingIds));
    formData.append("freightCompany", this.order.freightCompany);
    if (!this.order.freightCost) {
      this.order.freightCost = 0;
    }
    formData.append("freightCost", this.order.freightCost.toString());
    if (!this.order.distributionCost) {
      this.order.distributionCost = 0;
    }
    formData.append("distributionCost", this.order.distributionCost.toString());
    if (!this.order.packagingCost) {
      this.order.packagingCost = 0;
    }
    formData.append("packagingCost", this.order.packagingCost.toString());
    formData.append("printFiles", this.order.printFiles);
    formData.append("comments", this.order.comments);
    if (this.order.shippedDate) {
      formData.append("shippedDate", this.order.shippedDate?.toString());
    }
    if (this.order.plannedShipmentDate) {
      formData.append(
        "plannedShipmentDate",
        this.admissionDateFormat(this.order.plannedShipmentDate)
      );
    }
    formData.append("orderCompleted", "" + this.order.orderCompleted);
    if (this.order.orderCompleted) {
      if (!this.order.completedDate) {
        this.order.completedDate = new Date();
        const ad = this.order.completedDate.toISOString();
        formData.append("completedDate", ad);
      } else {
        formData.append("completedDate", this.order.completedDate.toString());
      }
    }

    formData.append("orderAccepted", "" + this.order.orderAccepted);
    if (this.order.orderAccepted) {
      if (!this.order.acceptedDate) {
        this.order.acceptedDate = new Date();
        const ad = this.order.acceptedDate.toISOString();
        formData.append("acceptedDate", ad);
      } else {
        formData.append("acceptedDate", this.order.acceptedDate.toString());
      }
    }
    formData.append("deliveryType", this.order.deliveryType);
    if (this.order.incoming) {
      formData.append("incoming", this.order.incoming.toString());
    }
    formData.append("supplierName", this.order.supplierName);
    this.changedStatus();
    return ordersModule
      .updateOrder({ formData: formData })
      .then((order: Order) => {
        this.order = order;
      })
      .catch((error: AxiosError) => {
        //
      })
      .finally(() => {
        this.changedStatus();
        this.fieldsAreFilled = this.allFieldsAreFilled() ? true : false;
        callback();
      });
  }
}
